.content {
  padding: var(--page-padding);
}

.onlyadmin {
  font-style: italic;
  color: grey;
  font-weight: normal;
}

.marginBottom {
  display: block;
  margin-bottom: 8px;
}
