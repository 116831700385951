.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
}

.root:hover {
  background: var(--hover);
}

.header {
  font-weight: bold;
}

.albumcover {
  height: 48px;
  width: 48px;
  margin-right: 16px;
  border-radius: 6px;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 4;
}

.albumname {
  margin-bottom: 4px;
  font-weight: bold;
}

.artistname {
  font-size: 0.9em;
  color: var(--text-grey);
}

.sumcount {
  flex: 1;
  text-align: center;
}

.sumduration {
  flex: 1;
  text-align: right;
}
