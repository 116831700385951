.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.item {
  border-radius: var(--radius) !important;
}
